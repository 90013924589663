/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

/* Aturan umum untuk kartu */

@keyframes spin {
  0%, 100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-20px);
  }
  50% {
    transform: translateX(20px);
  }
  75% {
    transform: translateX(-20px);
  }
}

.spin {
  animation: spin 1s ease-in-out;
  animation-iteration-count: infinite;
}

.textColorSecond{
  color: rgb(250,208,108);
}

.bgColorPrimary{
  background-color: rgb(1,20,26);
}

.bgColorPrimary2{
  background-color: rgb(41,50,65);
}

.bgColorPrimary3{
  background-color: rgb(223,251,252);
}

.bgColorSecond{
  background-color: rgb(250,208,108);
}

.bgColorSecond2{
  background-color: rgba(250,208,108,0.5);
}


